import '../../styles/main.scss'

const WSLoading = () => {
    return(
        <div className="text-center">
        <header className="header">
          <div className="loader" />
          <p className='header-text text-primary'>
            Loading...
          </p>
        </header>
      </div>
    )
}

export default WSLoading;
