import { Popup } from 'react-map-gl';
import { Dispatch, SetStateAction, useState } from 'react';

//hooks
import { useGetWSImage } from '../../hooks/useGetWSData';

//components and data
import WSData from '../../models/IWSData';

//helper
import { GetMatchingSchemeForWSPopup, GetMatchingSchemeForWSPopupBorder } from '../../helper/ColorScheme';

//styles
import '../../styles/main.scss'
import WSPopupText from './WSPopupText';

export const WSPopup = (props: {selectedLocation: WSData, setLocation:  Dispatch<SetStateAction<WSData | null>>}) => {
    
    const {imageUrl, isFetchingForSelected, fetchedImageError} = useGetWSImage(props.selectedLocation?.ImagePath ?? "");
    const [imageEnlarge, setImageEnlarge] = useState<boolean>(false);

    return(
        <Popup 
            key={props.selectedLocation.Id} 
            latitude={props.selectedLocation.Latitude} 
            longitude={props.selectedLocation.Longitude} 
            onClose={() => props.setLocation(null)}
            anchor='bottom'
            closeButton={false}
            className={`ws-popup ${GetMatchingSchemeForWSPopup(props.selectedLocation.Classification)}`}
        >
        {
            <div className='ws-box-size'>
            {
                isFetchingForSelected ? <div className='loader-box-height'> <div className="loader" /> </div> : 
                fetchedImageError ? <div className='loader-box-height body-text text-center'>  We apologies for the inconvenience.  <br /><br /> There were errors fetching image. </div> :
                <div className={`flex-row-space-between ws-popup-border ${GetMatchingSchemeForWSPopupBorder(props.selectedLocation.Classification)}`}>
                    <div className='box-shadow-overlay pointer-cursor' onClick={() => setImageEnlarge(true)} />
                    <WSPopupText data={props.selectedLocation} />
                    <img
                        className="ws-image"
                        src={imageUrl}
                        alt={`image_${props.selectedLocation.Id}`}
                    />
                    {imageEnlarge &&
                        <div className="overlay" onClick={() => setImageEnlarge(false)}>
                            <img
                                className="enlarged-image"
                                src={imageUrl}
                                alt={`image_${props.selectedLocation.Id}`}
                            />
                        </div>
                    }
                </div>
            }
            </div>
        }
        </Popup>
    )
}

export default WSPopup;
