import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, { Layer, MapLayerMouseEvent, NavigationControl, Source } from 'react-map-gl';

//config
import { GetMapBoxConfig } from '../../config/IMapBoxConfig';

//components and models
import WSData, { WSFilter } from '../../models/IWSData';
import WSPopup from '../WSPopup/WSPopup';
import WSFilterPanel from '../WSFilterPanel/WSFilterPanel';
import WSInfo from '../WSInfo/WSInfo';

//styles & layers & assets & icons
import '../../styles/main.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import { wsDataLayer } from './layers/WSDataLayer';
import logo from '../../assets/logo.png';


export const WSMap = (props: {data: WSData[], filter: WSFilter}) => {
  const mapRef = useRef<any>(null); 
  const mapStyle = require('../../styles/mapbox/style.json');
  
  const [data, setData] = useState<WSData[]>([]);
  const [displayWelcomeText, setDisplayWelcomeText] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<WSData | null>(null);
  const [selectedClassifications, setSelectedClassification] = useState<string[]>(props.filter.Classifications);
  
  //datapoints is the data that will be stored and cached in source. 
  const dataPoints = useMemo(() => {
    let res = data.map(d => {
      return {
        type: 'Feature', 
        geometry: {type: 'Point', 
        coordinates: [d.Longitude, d.Latitude]}, 
        properties: {
          id: d.Id, 
          imageId: d.ImageId,
          longitude: d.Longitude,
          latitude: d.Latitude,
          city: d.City,
          country: d.Country,
          project: d.Project, 
          imagePath: d.ImagePath,
          classification: d.Classification,
          detectionId: d.DetectionId,
          color: d.Classification
        }}
    })

    return { type: 'FeatureCollection', features: res };
  }, [data]);

  useEffect(() => {
    if(selectedClassifications.length === 0){
      setData([]);
      return;
    }

    if(selectedClassifications.length === props.filter.Classifications.length){
      setData(props.data)
      return;
    }

    var filteredData = props.data.filter(d => selectedClassifications.includes(d.Classification));
    setData(filteredData)

  }, [selectedClassifications])

  //set cursor to change when datapoint is detected
  //this is set to trigger on welcometext since the map will load
  //before the text is displayed when enter the map 
  //the displaytext will close hence trigger the effect on the initalized map 
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      map.on('mouseenter', 'wsDataLayer', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'wsDataLayer', () => {
        map.getCanvas().style.cursor = '';
      });
    }
  }, [displayWelcomeText]); 

  //solution to be able to click on data in layers
  const onClick = useCallback((event: MapLayerMouseEvent) => {
    const { features } = event;
    const clickedFeature = features && features[0];

    if(clickedFeature !== undefined && clickedFeature.properties !== null ){

      mapRef.current.flyTo({
        center: [clickedFeature.properties.longitude, clickedFeature.properties.latitude + 0.015],
        zoom: 13
      });

      let selectedLocation: WSData = {
        Id: clickedFeature.properties.id,
        ImageId: clickedFeature.properties.imageId,
        Longitude: clickedFeature.properties.longitude,
        Latitude: clickedFeature.properties.latitude,
        City: clickedFeature.properties.city,
        Country: clickedFeature.properties.country,
        Project: clickedFeature.properties.project,
        ImagePath: clickedFeature.properties.imagePath,
        Classification: clickedFeature.properties.classification,
        DetectionId: clickedFeature.properties.detectionId
      }

      setSelectedLocation(selectedLocation);
    }
  }, []);

  return (
    <div className='map'>
      <Map
        ref={mapRef} 
        mapboxAccessToken={GetMapBoxConfig.Token}
        initialViewState={{
          longitude: 46.7031,
          latitude: 24.657,
          zoom: 11,
        }}
        attributionControl={false}
        style={{width: "100vw", height: "100vh", cursor: 'auto'}}
        mapStyle={mapStyle}
        onClick={onClick}
        interactiveLayerIds={['wsDataLayer']}
      >    
        {
          displayWelcomeText && 
          <WSInfo first={true} setDisplayWelcomeText={setDisplayWelcomeText}/>
        }
        {
          displayWelcomeText === false && (
            <>
              <WSFilterPanel 
                wsFilterObjects={props.filter}
                selectedFilterOptions={selectedClassifications} 
                setSelectedFilterOptions={setSelectedClassification}
              />
              <NavigationControl visualizePitch={true}/>
        
              {/* using source and layer to avoid re-rendering markers. 
                  This will enhance the performance since the datapoints 
                  will not be re-rendered when the map for example is moved */}
              <Source id="marker-source" type="geojson" data={dataPoints}>
                <Layer {...wsDataLayer} />  
              </Source>
            </>
          )
        }
        {
          selectedLocation !== null && 
          <WSPopup selectedLocation={selectedLocation} setLocation={setSelectedLocation} />
        }
        
        <div className="logo-container">
          <a href="https://walkscapes.org" target="_blank">
            <img src={logo} alt="logo" height="75px" />
          </a>
        </div>                     

      </Map>
    </div>
  );
}


export default WSMap;

