import { Dispatch, SetStateAction } from 'react';
import '../../styles/main.scss'

const WSInfo = (props: { first: boolean, setDisplayWelcomeText: Dispatch<SetStateAction<boolean>> }) => {
    return(
      <div className={`${props.first ? "welcome-text-container": ""}`}>
        <div className="box-toolbox"> 
          <h2 className='header-text text-center'>{"<"}walkscapes-ai{">"}</h2>
          <p className={`body-text ${props.first ? "text-center": ""}`}>
            walkscapes is an <b>urbanism studio </b> <br />
            founded by architect and urban designer <br />
            <b>Mohammed Almahmood</b> in Copenhagen and Riyadh. It provides <br />
            <b>architecture</b> and <b>urban design</b> <br />
            services through cutting-edge smart <br />
            city technologies with an aim to deliver <br />
            <b>people-oriented cities</b> and <b>public spaces</b> <br /> 
            based on <b>evidence-based urbanism</b> approach.
          </p>

          <br/>
          
          {
            props.first &&
            <button 
              className='btn-center btn-primary button-text'
              onClick={() => props.setDisplayWelcomeText(false)}
            > 
              {"<explore>"}
            </button>
          }
          
        </div>
      </div>
    )
}

export default WSInfo;
