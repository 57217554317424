import { Dispatch, SetStateAction, memo, useEffect, useRef, useState } from "react";

//styles & assets & icons
import 'mapbox-gl/dist/mapbox-gl.css'
import '../../styles/main.scss'
import { FaFilter } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

//data and components
import { WSFilter } from "../../models/IWSData";
import WSInfo from "../WSInfo/WSInfo";

//helper
import FormatText from "../../helper/TextFormatter";
import { GetMatchingSchemeForInputClass } from "../../helper/ColorScheme";

export const WSFilterPanel = (props: {
    wsFilterObjects: WSFilter, selectedFilterOptions: string[], 
    setSelectedFilterOptions: Dispatch<SetStateAction<string[]>>
}) => {

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [infoIsClicked, setInfoIsClicked] = useState<boolean>(false);
    const [filterIsClicked, setFilterIsClicked] = useState<boolean>(false);

    const handleSelectedClick = (option: string) => {
        if (props.selectedFilterOptions.includes(option)) {
            props.setSelectedFilterOptions(props.selectedFilterOptions.filter((selectedOption) => selectedOption !== option));
        } else {
            props.setSelectedFilterOptions([...props.selectedFilterOptions, option]);
        }
    };

    const handleAllOptionsClick = () => {
        if (props.selectedFilterOptions.length === props.wsFilterObjects.Classifications.length) {
            props.setSelectedFilterOptions([]);
        } else {
            props.setSelectedFilterOptions([...props.wsFilterObjects.Classifications]);
        }
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setFilterIsClicked(false);
            setInfoIsClicked(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return(
    <div className="box-container" ref={dropdownRef}>
        <div className="flex-row">
            <button className="filter-panel filter-panel-top-right" onClick={e => {e.stopPropagation(); setInfoIsClicked(!infoIsClicked); setFilterIsClicked(false)}}> 
                <GoDotFill className="logo-icon" />
            </button>
            <button className="filter-panel filter-panel-center" onClick={e => {e.stopPropagation(); setFilterIsClicked(!filterIsClicked); setInfoIsClicked(false)}}> 
                <FaFilter />
            </button>
        </div>
        {
            infoIsClicked &&
            <WSInfo first={false} setDisplayWelcomeText={setInfoIsClicked}/>
        }
        {
            filterIsClicked &&
            <div className="box-toolbox">
                <h2 className="header-text">filter</h2>
                <label className="accent-text list-primary">
                    <input
                        type="checkbox"
                        checked={props.selectedFilterOptions.length === props.wsFilterObjects.Classifications.length}
                        onChange={handleAllOptionsClick}
                        className={`box-toolbox-input ${GetMatchingSchemeForInputClass("white")}`}
                    />
                    Select All
                </label>
                {
                    props.wsFilterObjects?.Classifications?.map((option) => (
                        <label className="accent-text list-primary" key={`filter_${option}`}>
                            <input
                                type="checkbox"
                                checked={props.selectedFilterOptions.includes(option)}
                                onChange={() => handleSelectedClick(option)}
                                className={`box-toolbox-input ${GetMatchingSchemeForInputClass(option)}`}
                            />
                            {FormatText(option)}
                        </label>
                        )
                    )
                }
            </div>
        }
    </div>
    )
}

export default memo(WSFilterPanel);
