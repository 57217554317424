import { CircleLayer } from "react-map-gl";
import GetLayerColorMapping from "../../../helper/ColorScheme";


export const wsDataLayer: CircleLayer = {
    id: 'wsDataLayer',
    type: 'circle',
    paint: {
      'circle-radius': 5,
      'circle-opacity': 0.9,
      'circle-stroke-width': 1,
      'circle-stroke-color': "#242121",
      'circle-color': GetLayerColorMapping()
    },
    source: "wsdata",
};