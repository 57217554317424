//components
import WSError from '../WSError/WSError';
import WSLoading from '../WSLoading/WSLoading';
import WSMap from '../WSMap/WSMap';

//hooks
import useGetWSData, { useGetWSClasses } from '../../hooks/useGetWSData';
import { WSFilter } from '../../models/IWSData';

const WSHome = () => {

  //load init data
  const {wsData, isLoading, error} = useGetWSData();
  const {wsFilterObjects, isLoadingDataForFilter, dataFilterError} = useGetWSClasses();
  
  return(
    <>
      {
        (isLoading === true || isLoadingDataForFilter == true) ? <WSLoading /> 
        :
        (error === true || dataFilterError === true) ? <WSError /> 
        : 
        <WSMap data={wsData} filter={wsFilterObjects as WSFilter}  />
      }
    </>
  )
}


export default WSHome;
