import { Route, Routes } from 'react-router-dom';
import WSHome from './components/WSHome/WSHome';

function App() {
  return (
    <Routes>
        <Route path="/" element={<WSHome />} />
    </Routes>    
  );
}

export default App;
