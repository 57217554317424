//components and data
import FormatText from "../../helper/TextFormatter";
import WSData from "../../models/IWSData";

//styles
import '../../styles/main.scss'

export const WSPopupText = (props: {data: WSData }) => {
    return(
    <div className="ws-popup-text">
        <div className="header-text image-header-text"> {props.data.City} </div>
        <div className='accent-text'> 
            <p className='image-text'> {"<Project: " + props.data.Project + ">"} </p>
            <p className='image-text'> {"<Class: " + FormatText(props.data.Classification) + ">"} </p>
            <p className='image-text'> {"<Pos: " + props.data.Longitude + ", " + props.data.Latitude + ">"} </p>
            <p className='last-image-text'> {"<Detection id: " + props.data.DetectionId +  ">"} </p>
        </div>
    </div>
    )
}

export default WSPopupText;