import { useEffect, useState } from "react";
import WSData, { WSFilter } from "../models/IWSData";
import { GetWSApiConfig } from "../config/IApiConfig";

export default function useGetWSData(){

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setHasError] = useState<boolean>(false);
    const [wsData, setWsData] = useState<WSData[]>([]);

    useEffect(() => {
        
        const getWSData = async () => {

            try
            {
                const result = await fetch( `${GetWSApiConfig.baseUrl}/getall`)

                if (result.status === 200){
                    setWsData(await result.json());
                }else{
                    setHasError(true);
                }
            }
            catch(e)
            {
                setHasError(true);
            }

            setIsLoading(false)
        };
    
        getWSData();

      }, []);


    return {wsData, isLoading, error};
}


export function useGetWSClasses(){

    const [isLoadingDataForFilter, setIsLoadingDataForFilter] = useState<boolean>(true);
    const [dataFilterError, setDataFilterError] = useState<boolean>(false);
    const [wsFilterObjects, setWsFilterObjects] = useState<WSFilter|null>(null);

    useEffect(() => {
        
        const getWSClasses = async () => {
            try
            {
                const result = await fetch( `${GetWSApiConfig.baseUrl}/getalldetectionclasses`)

                if (result.status === 200){
                    setWsFilterObjects(await result.json())
                }else{
                    setDataFilterError(true);
                }
            }
            catch(e)
            {
                setDataFilterError(true);
            }

            setIsLoadingDataForFilter(false)
        };
    
        if(wsFilterObjects === null){
            getWSClasses();
        }

      }, []);


    return {wsFilterObjects, isLoadingDataForFilter, dataFilterError};
}

export function useGetWSImage(imagePath: string){

    const [isFetchingForSelected, setIsFetchingForSelected] = useState<boolean>(false);
    const [fetchedImageError, setHasFetchedImageError] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>("");

    useEffect(() => {
        
        setImageUrl("");
        setIsFetchingForSelected(true);
        setHasFetchedImageError(false);

        const getWSImage = async () => {
            try
            {
                const response = await fetch(`${GetWSApiConfig.baseUrl}/getimage?path=${imagePath}`);

                await delay(500);
                if (response.status === 200) 
                {
                    const imageUrl = await response.json();
                    setHasFetchedImageError(false);
                    setImageUrl(imageUrl);
                } 
                else 
                {
                    setHasFetchedImageError(true);
                }
            } 
            catch (error) 
            {
                setHasFetchedImageError(true);
            }

            setIsFetchingForSelected(false)
        };
    
        getWSImage();

      }, [imagePath]);


    return {imageUrl, isFetchingForSelected, fetchedImageError};
}

function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}