import colorScheme from '../styles/_variables.module.scss'

export default function GetLayerColorMapping() : any {
    return [
        'match',
        ['get', 'color'],
        'black', colorScheme.black,
        'white', colorScheme.white,
        'gray', colorScheme.gray,
        'CLUTTER_SIDEWALK', colorScheme.clutter,
        'GARBAGE', colorScheme.garbage,
        'GRAFFITI', colorScheme.graffiti,
        'BAD_BILLBOARD', colorScheme.bad_billboard,
        'POTHOLES', colorScheme.potholes,
        'CONSTRUCTION_ROAD', colorScheme.construction_road,
        colorScheme.black
    ];
} 

export function GetMatchingSchemeForColorClass(val: string) : string{
    switch ( val ) 
    {
        case "black":
            return "color-black"
        case "white":
            return "color-white"
        case "gray":
            return "color-gray"
        case "CLUTTER_SIDEWALK":
            return "color-clutter"
        case "GARBAGE":
            return "color-garbage"
        case "GRAFFITI":
            return "color-graffiti"
        case "BAD_BILLBOARD":
            return "color-bad-billboard"
        case "POTHOLES":
            return "color-potholes"
        case "CONSTRUCTION_ROAD":
            return "color-construction-road"
        default: 
            return "color-black"
     }
}

export function GetMatchingSchemeForInputClass(val: string) : string{
    switch ( val ) 
    {
        case "black":
            return "box-toolbox-input-black"
        case "white":
            return "box-toolbox-input-white"
        case "gray":
            return "box-toolbox-input-gray"
        case "CLUTTER_SIDEWALK":
            return "box-toolbox-input-clutter"
        case "GARBAGE":
            return "box-toolbox-input-garbage"
        case "GRAFFITI":
            return "box-toolbox-input-graffiti"
        case "BAD_BILLBOARD":
            return "box-toolbox-input-bad-billboard"
        case "POTHOLES":
            return "box-toolbox-input-potholes"
        case "CONSTRUCTION_ROAD":
            return "box-toolbox-input-construction-road"
        default: 
            return "box-toolbox-input-black"
     }
}

export function GetMatchingSchemeForWSPopup(val: string) : string{
    switch ( val ) 
    {
        case "black":
            return "ws-popup-black"
        case "white":
            return "ws-popup-white"
        case "gray":
            return "ws-popup-gray"
        case "CLUTTER_SIDEWALK":
            return "ws-popup-clutter"
        case "GARBAGE":
            return "ws-popup-garbage"
        case "GRAFFITI":
            return "ws-popup-graffiti"
        case "BAD_BILLBOARD":
            return "ws-popup-bad-billboard"
        case "POTHOLES":
            return "ws-popup-potholes"
        case "CONSTRUCTION_ROAD":
            return "ws-popup-construction-road"
        default: 
            return "ws-popup-black"
     }
}

export function GetMatchingSchemeForWSPopupBorder(val: string) : string{
    switch ( val ) 
    {
        case "black":
            return "ws-popup-border-color-black"
        case "white":
            return "ws-popup-border-color-white"
        case "gray":
            return "ws-popup-border-color-gray"
        case "CLUTTER_SIDEWALK":
            return "ws-popup-border-color-clutter"
        case "GARBAGE":
            return "ws-popup-border-color-garbage"
        case "GRAFFITI":
            return "ws-popup-border-color-graffiti"
        case "BAD_BILLBOARD":
            return "ws-popup-border-color-bad-billboard"
        case "POTHOLES":
            return "ws-popup-border-color-potholes"
        case "CONSTRUCTION_ROAD":
            return "ws-popup-border-color-construction-road"
        default: 
            return "ws-popup-border-color-black"
     }
}