import logo from '../../assets/logo.png';
import '../../styles/main.scss'

const WSError = () => {
    return(
        <div className="text-center">
        <header className="header">
          <img src={logo} alt="logo" className="error-image" />
          <p className='accent-text text-primary'>
            Apologies for the inconvenience. Unexpected server error occured, please reload
          </p>
        </header>
      </div>
    )
}

export default WSError;
